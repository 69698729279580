import { COUNTRY_CODES } from "../utils/countries";

export interface PaymentType {
  date: Date;
  amount: number;
}

export interface ApplicationType {
  affiliate: JSON;
}

export interface OfferType {
  id: string;
  createdAt: Date;
  signedAt: Date;
  shortId: string;
  applicationId: string;
  savedAmount: number;
  hasDiscountCode: boolean;
  amount: number;
  originalAmount: number;
  months: number;
  pricingMonths: number;

  amortizationRate: number;
  monthlyPayment: number;
  effectiveApr: number;
  financingCost: number;
  interestRate: number;
  monthlyFee: number;
  oldEffectiveApr?: number;
  oldFinancingCost: number;
  oldInterestRate: number;
  oldMonthlyFee: number;
  originalMonths?: number;
  originalMonthlyPayment: number;
  promoCode: {
    type: string;
    code: string;
    amount: number;
  };

  lender: LenderType;
  isBaseInterestRateUncertain: boolean;
  nextPayments: PaymentType[];
  contract: {
    id: string;
    createdAt: Date;
    publicUrl: string;
  };

  externalStatement: any;
  application: any;
  interestRateDetails?: {
    floatingInterestRate?: number;
    marginInterestRate?: number;
  };
}

export interface LenderType {
  id: string;
  name: string;
  brandAttributes: unknown;
  displayName: string;
}

export enum ScriveSource {
  mypages = "mypages",
  universal = "universal"
}

export interface CustomerType {
  id: number;
  personalIdentifier: string;
  email: string;
  name: string;
  countryCode: COUNTRY_CODES;
  firstName: string;
  address: string;
  referrerId: string;
  hasFlexOffersOrLoans: string;
  hasActiveLoans: string;
  isDemo: boolean;

  features: Record<string, string>;

  /** @deprecated */
  offersToken: string;
  offers: OfferType[];
  creditor: {
    id: string;
    displayName: string;
    type: string;
  };
  nextPayments: PaymentType[];
  offersVariation: string;
  applications: ApplicationType[];
}
