import React from "react";
import { useTranslation, Trans } from "react-i18next";
import { Typography, Box } from "@anyfin/ui";

import Link from "../../../components/Link";
import OfferTermsModal from "./OfferTermsModal";
import Section from "../../../components/Section";
import CopyContainer from "../../../components/CopyContainer";
import { COUNTRY_CODES } from "../../../utils/countries";
import { CustomerType, OfferType } from "../../../types/types";
import styled from "styled-components";
import { isNil } from "lodash";

import {
  formatMoney,
  formatPercent
} from "@anyfin/number-formatter/dist/cjs/index";

const StyledLink = styled(Link)`
  font-weight: 500;
  text-decoration: underline !important;
`;
interface OfferTermsProps {
  countryCode: COUNTRY_CODES;
  customer: CustomerType;
  offers: Array<OfferType>;
  policy: any;
}
const OfferTerms = ({
  countryCode,
  customer,
  offers,
  policy
}: OfferTermsProps) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const { t } = useTranslation("offer");
  const country = countryCode;

  const toggleModal = () => setModalOpen(!modalOpen);
  const creditorName = customer.creditor?.displayName;

  return (
    <>
      <Section>
        <CopyContainer
          style={{ borderTop: "2px solid #E0E0E0" }}
          paddingLeft={1.5}
          paddingRight={1.5}
        >
          <Box marginBottom="medium" marginTop="xxlarge">
            <Typography.H4>{t("offer_terms.title")}</Typography.H4>
          </Box>
          <Box marginBottom="large">
            <Typography size={1}>
              <Trans
                t={t}
                i18nKey="offer_terms.description"
                values={{ creditorName }}
                components={[
                  <StyledLink
                    key={0}
                    onClick={toggleModal}
                    style={{ cursor: "pointer" }}
                  />
                ]}
              ></Trans>
            </Typography>
          </Box>
          {countryCode === COUNTRY_CODES.FI ? (
            <>
              {offers.map((offer, index) => {
                const { floatingInterestRate, marginInterestRate } =
                  offer.interestRateDetails || {};
                if (
                  !isNil(floatingInterestRate) &&
                  !isNil(marginInterestRate)
                ) {
                  if (!offer.months) {
                    // flex loan
                    return (
                      <Box marginBottom={1} key={offer.id}>
                        <Typography key={offer.id} size={1}>
                          {t("offer_terms.flex_loan_terms_fi", {
                            loanNumber: index + 1,
                            loanAmount: formatMoney(offer?.amount, country),
                            euribor: formatPercent(
                              floatingInterestRate,
                              country
                            ),
                            margin: formatPercent(marginInterestRate, country),
                            nominalInterest: formatPercent(
                              offer?.interestRate,
                              country
                            ),
                            effectiveInterest: formatPercent(
                              offer?.effectiveApr,
                              country
                            ),
                            monthlyFee: formatMoney(offer?.monthlyFee, country),
                            amortizationRate: formatPercent(
                              offer.amortizationRate,
                              country
                            )
                          })}
                        </Typography>
                      </Box>
                    );
                  } else {
                    // annuity loan
                    return (
                      <Box marginBottom={1} key={offer.id}>
                        <Typography key={offer.id} size={1}>
                          {t("offer_terms.annuity_loan_terms_fi", {
                            loanNumber: index + 1,
                            loanAmount: formatMoney(offer?.amount, country),
                            euribor: formatPercent(
                              floatingInterestRate,
                              country
                            ),
                            margin: formatPercent(marginInterestRate, country),
                            nominalInterest: formatPercent(
                              offer?.interestRate,
                              country
                            ),
                            effectiveInterest: formatPercent(
                              offer?.effectiveApr,
                              country
                            ),
                            monthlyFee: formatMoney(offer?.monthlyFee, country),
                            duration: offer.months
                          })}
                        </Typography>
                      </Box>
                    );
                  }
                } else {
                  return null;
                }
              })}
            </>
          ) : null}
        </CopyContainer>
      </Section>
      <OfferTermsModal
        visible={modalOpen}
        toggleModal={toggleModal}
        offers={offers}
        policy={policy}
        demoProvider={customer.isDemo ? t("demo_lender") : undefined}
      />
    </>
  );
};

export default OfferTerms;
