import { Box, Typography } from "@anyfin/ui";
import CopyContainer from "../../../components/CopyContainer";

import disclaimer from "../../../assets/offer/alert-triangle-filled@3x.png";
import { Trans, useTranslation } from "react-i18next";
import Link from "../../../components/Link";
import styled from "styled-components";

const StyledLink = styled(Link)`
  text-decoration: none !important;
`;

function OfferDisclaimer() {
  const { t } = useTranslation("offer");
  return (
    <CopyContainer paddingLeft={1.5} paddingRight={1.5}>
      <Box marginTop="small">
        <img
          src={disclaimer}
          alt="Alert Triangle"
          style={{ width: "56px", height: "56px", marginBottom: "8px" }}
        />
        <Typography size={1} medium>
          {t("offer_terms_disclaimer.title")}
        </Typography>
        <Typography size={1}>
          <Trans
            t={t}
            i18nKey="offer_terms_disclaimer.copy"
            components={[
              <StyledLink
                key={0}
                href="https://www.konsumentverket.se"
                target="_blank"
                style={{ cursor: "pointer" }}
              />
            ]}
          ></Trans>
        </Typography>
      </Box>
    </CopyContainer>
  );
}

export default OfferDisclaimer;
