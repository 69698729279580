import React from "react";
import { Box, Icon, ListItem, Typography } from "@anyfin/ui";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";
import { CountryCode, formatMoney } from "@anyfin/number-formatter";
import { useCountry } from "@anyfin/number-formatter/components";
import Section from "../../../components/Section";

const StyledListItem = styled(ListItem)`
  background-color: ${props => {
    return props.theme.colors.success.light;
  }};
  border-radius: 1.5rem;
`;

const IconContainer = styled(Box)`
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => {
    return props.theme.colors.success.light;
  }};
`;

interface ReferralProps {
  discount: number;
  isReferred: boolean;
  hasFreeInterest: boolean;
}

const getTranslationKey = (isReferred: boolean, hasFreeInterest: boolean, discount: number) => {
  if (isReferred) {
    return "offer_promo_code.added";
  } else if (hasFreeInterest && discount > 1) {
    return "offer_promo_code.interest_free_other";
  } else if (hasFreeInterest && discount <= 1) {
    return "offer_promo_code.interest_free_one";
  } else {
    return "offer_promo_code.discount_added";
  }
};

const getMonthOrCurrencyDiscount = (discount: number, hasFreeInterest: boolean, country: CountryCode) => {
  if (hasFreeInterest) {
    return discount;
  } else {
    return formatMoney(discount, country);
  }
}

const Referral = ({ isReferred, discount, hasFreeInterest }: ReferralProps) => {
  const { t } = useTranslation("offer");
  const [country] = useCountry();

  return (
    <Section>
      <Box marginLeft={0.5} marginRight={0.5}>
        <StyledListItem
          floating={false}
          dataLeft={
            <IconContainer>
              <Icon icon="Check" size={1.25} />
            </IconContainer>
          }
        >
          <Trans
            t={t}
            i18nKey={getTranslationKey(isReferred, hasFreeInterest, discount)}
            values={{
              discount: getMonthOrCurrencyDiscount(discount, hasFreeInterest, country),
            }}
            components={[<Typography key={0} medium />]}
          ></Trans>
        </StyledListItem>
      </Box>
    </Section>
  );
};

export default Referral;
